import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { GuessRow } from './components/guess-row';

function App() {
  return (
    <div className="App">
      <div className='container-main'>
        Kirtdle
        <GuessRow />
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
