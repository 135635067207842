import { useState } from "react";
import { WinModal } from './win-modal';
import { getWord } from "./utils/get-word";
import { ALLOWED_WORDS } from './utils/allowed-words';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';


import { toast } from 'react-toastify';

const solution = getWord(`${new Date().toDateString()}`);
function generateGuessString(gh) {
    const g =  gh.map((row) => {
        return row.split('').map((letter, index) => {
            if (solution.indexOf(letter) === -1) {
                return '😢'
            }
            if (solution[index] === letter) {
                return '🤓'
            }
            if (solution.indexOf(letter) !== -1) {
                return '🥸'
            }
            return '';
        }).join('') + '\n';
    })
    return `Kirtdle ${days_passed(new Date('02/20/2022'))}\n${g.join('')}`
}
function getShare(guess) {
    navigator.clipboard.writeText(generateGuessString(guess)).then(() => {
        toast('Copied to clipboard', {
            position: 'top-center',
            theme: 'dark',
            hideProgressBar: true,
            closeButton: false,
            style: {marginTop: '80px'},
            pauseOnHover: false,
            autoClose: 500,
        });
    });

}
function getBackgroundClass(letter, index, solution) {
    if (!letter || letter === ' ') {
        return 'letter-row';
    }
    if (solution[index] === letter) {
        return 'letter-row green-bg';
    }
    if (solution.indexOf(letter) !== -1) {
        return 'letter-row yellow-bg'
    } else {
        return 'letter-row fail-bg'
    }
}
function days_passed(dt) {
    const current = new Date();
    const Difference_In_Time = current.getTime() - dt.getTime();

    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
}


const WinFragment = ({ handleClose, guess }) => {
    return (
        <div>
            <div><button className="close-button" type="button" onClick={(handleClose)}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  </svg></button></div>
  <div>You Win!</div>
            <div>Kirtdle {days_passed(new Date('02/20/2022'))}</div>
            <div className="share-container">
                <button className="share-button" onClick={() => getShare(guess)}>Share</button>
            </div>
        </div>
    )
}

const LoseFragment = () => {
    return (
        <div>
            <div>You Lose</div>
            <div>Kirtdle {days_passed(new Date('02/20/2022'))}</div>
        </div>
    )
}
export const GuessRow = () => {
    const [guessHistory, setGuessHistory] = useState([]);
    const [guess, setGuess] = useState(['', '', '', '', '']);
    const [guessIndex, setGuessIndex] = useState(0);
    const [showingModal, setShowingModal] = useState(false);
    const [won, setWon] = useState(null);

    const keyHandler = (e) => {
        if (won !== null) {
            return;
        }
        if (e.key === 'Enter' || e.key === 'ENTER') {
            if (!ALLOWED_WORDS.includes(guess.join(''))) {
                setGuess(['', '', '', '', '']);
                setGuessIndex(0);
                toast('Not in word list', {
                    position: 'top-center',
                    theme: 'dark',
                    hideProgressBar: true,
                    closeButton: false,
                    style: {marginTop: '80px'},
                    pauseOnHover: false,
                    autoClose: 500,
                });
                return
            }
            setGuessHistory([...guessHistory, guess.join('')])
            setGuess(['', '', '', '', '']);
            setGuessIndex(0);
            if (guess.join('') === solution) {
                setWon(true);
                setShowingModal(true);
                setTimeout(() => {
                }, 500);
            } else if (guessHistory.length === 5) {
                setWon(false);
                setShowingModal(true);
            }
        }
        if (e.key === 'Backspace' || e.key === '🔙') {
            const newGuess = [...guess];
            newGuess[guessIndex - 1] = ''
            setGuess(newGuess);
            setGuessIndex(guessIndex - 1);
        }
        if (e.key.match(/^[a-z]$/) && guessIndex < guess.length) {
            const newGuess = [...guess];
            newGuess[guessIndex] = e.key;
            setGuess(newGuess);
            setGuessIndex(guessIndex + 1);
        }
    };

    document.onkeydown = keyHandler;

    return (
        <div className="container-wordle" >
            {guessHistory.map((hGuess) => {
                return (
                    <div className="row">
                        {
                        hGuess.split('').map((g, index) =>
                        <div className={getBackgroundClass(g, index, solution)}>{hGuess[index]}</div>
                        )
                        }
                    </div>
                );
            })}
            <div className="row">
                {guess.map((g, index) => <div className='letter-row'>{guess[index]}</div>)}
            </div>
            <WinModal show={showingModal}>
                {won === true ? <WinFragment handleClose={() => setShowingModal(false)} guess={guessHistory} />: <LoseFragment />}
            </WinModal>
            <div className="keyboard-div">
                <Keyboard layout={{ default: [
                    'q w e r t y u i o p',
                    'a s d f g h j k l',
                    'ENTER z x c v b n m 🔙'
                ]}}
                onKeyPress={(key) => keyHandler({ key })}
                 />
            </div>
        </div>


    )
}
